import defaultImageUrl from '#brand/config/app/components/product/defaultImageUrl'

/**
 * Checks if the image URL is valid, otherwise passes the default image URL
 * @param {string} imageUrl - Image URL passed from the product
 * @category Utils
 */
export const resolveProductImgUrl = (imageUrl: string) => {
  try {
    return new URL(imageUrl).href
  }
  catch {
    return defaultImageUrl
  }
}
